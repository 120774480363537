import environment from '../config/environment.json';
const websiteShortCode = () => environment.websiteShortCode;
const adminPanelUrl = () => environment.adminPanelUrl;
const apiEndpoint = () => environment.apiEndpoint;
const signalrEndpoint = () => environment.signalrEndpoint;
const apiPdfEndpoint = () => environment.apiPdfEndpoint;
const debug = () => environment.debug;
const kountUrl = () => environment.kountUrl;
const countries = () => environment.countries;
const reCaptchaKey = () => environment.reCaptchaKey;
const getAWSBucketEndpoint = endpoint => environment.amazonAwsUrl.replace('dynamic', endpoint);
const baseUrl = () => environment.baseUrl;
const chicksGroupBaseUrl = () => environment.chicksGroupBaseUrl;
const clientId = () => environment.clientId;
const chicksXBaseUrl = () => environment.chicksXBaseUrl;
const accKingsBaseUrl = () => environment.accKingsBaseUrl;
const gamerTotalBaseUrl = () => environment.gamerTotalBaseUrl;
const divicaSalesBaseUrl = () => environment.divicaSalesBaseUrl;
const chicksGoldBlogsUrl = () => environment.chicksGoldBlogsUrl;
const firebaseConfig = () => environment.firebaseConfig;
const googlePayMerchantId = () => environment.googlePayMerchantId;
const discordAuthUrl = () => environment.discordAuthUrl;
const googleSignInClientId = () => environment.googleSignInClientId;
const discordSignInUrl = () => environment.discordSignInUrl;
const discordSignUpUrl = () => environment.discordSignUpUrl;
const applePayMerchantId = () => environment.applePayMerchantId;
const appleClientId = () => environment.appleClientId;
const environmentName = () => environment.environment;
const vgsInboundRouteId = () => environment.vgsInboundRouteId;
const vgsGooglePayInboundRouteId = () => environment.vgsGooglePayInboundRouteId;
const vgsApplePayInboundRouteId = () => environment.vgsApplePayInboundRouteId;
const vgsVaultId = () => environment.vgsVaultId;
const vgsEnv = () => environment.vgsEnv;
const vgsOrganizationId = () => environment.vgsOrganizationId;
const fingerprintApiKey = () => environment.fingerprintApiKey;
const fingerprintDomainUrl = () => environment.fingerprintDomainUrl;
const stripeConfig = () => environment.stripe;
export default this;
export { websiteShortCode, adminPanelUrl, apiEndpoint, signalrEndpoint, apiPdfEndpoint, reCaptchaKey, kountUrl, countries, getAWSBucketEndpoint, baseUrl, chicksXBaseUrl, accKingsBaseUrl, gamerTotalBaseUrl, divicaSalesBaseUrl, chicksGoldBlogsUrl, firebaseConfig, googlePayMerchantId, discordAuthUrl, googleSignInClientId, discordSignInUrl, discordSignUpUrl, applePayMerchantId, appleClientId, debug, environmentName, vgsInboundRouteId, vgsGooglePayInboundRouteId, vgsApplePayInboundRouteId, vgsVaultId, vgsEnv, vgsOrganizationId, fingerprintApiKey, fingerprintDomainUrl, chicksGroupBaseUrl, clientId, stripeConfig };